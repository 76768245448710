/* ================  COLORS ================ */
$color-brand-primary: #1875bc;
$color-brand-secondary: #515e65;

$color-gray: red;
$color-orange: #00c2cb;
$color-white: #fff; // White
$color-black: #000;
$color-white-warm-1: #f7f7f7;
$color-white-warm-2: #e0e0e0;
$color-white-warm-3: #f1f1f1;
$color-white-warm-4: #F5F5F7;


$color-gray-1: #333333;
$color-gray-3: #828282;


// Break Points (min)
$xxs_min: 500px;
$xs_min: 600px;
$sm_min: 768px;
$md_min: 992px;
$lg_min: 1200px;
$xl_min: 1400px;
$xxl_min: 1600px;
$xxxl_min: 1920px;

// Break Points (max)
$xxs_max: $xxs_min - 1;
$xs_max: $xs_min - 1;
$sm_max: $sm_min - 1;
$md_max: $md_min - 1;
$lg_max: $lg_min - 1;
$xl_max: $xl_min - 1;
$xxl_max: $xxl_min - 1;
$xxxl_max: $xxxl_min - 1;

// Fonts
$font_family: 'Inter', sans-serif;

// Logo_Dimensions
$logo_full_height: 50%;
$logo_full_width: 100%;

// Typography
$header01: 25px;
$header02: 22px;
$header03: 18px;
$paragraph01: 22px;
$paragraph02: 18px;
$paragraph03: 14px;

/* ================  MIXINS ================ */
// @mixin font-gilroy-regular {
//   font-family: 'GilroyRegular', Arial, sans-serif;
// }
// @mixin font-gilroy-bold {
//   font-family: 'GilroyBold', Arial, sans-serif;
// }

// @mixin border-bottom {
//   border: 1px solid $color-white-warm-2;
// }

@mixin shadow {
  box-shadow: 0px 4px 20px rgba(179, 179, 179, 0.25);
}

:export{
  primary_color: $color-brand-primary;
  secondary_color:$color-brand-secondary;
  white_color:$color-white;
  black_color:$color-black;
}
