.priority-label{
  width: auto;
  height: auto;
  border: 1px solid #EA0000;
  border-radius: 5px;
  padding:1px 4px;

  p{
    margin: 0px;
    padding:0px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    white-space: nowrap;
  }
}

.pointsOverviewContainer{
  padding: 15px;
  min-width: 347px;
  min-height: 97px;
  flex-shrink: 0; 
  border-radius: 25px;
  border: 6px solid #FFB800;
  background: #373333;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

  &.small{
    min-width: 150px;
  }
}


.cardExpandButton{
  padding: 10px;
  border-radius: 0px 25px 25px 0px;
  background: #FFB800;
  width: 45px;
  height: 135px;
  overflow: visible;
  position: fixed;
  z-index: 99;
  top: 45vh;
  left: 70vw;
  display: flex;
  align-items: center;

  &.open{
    left: 80vw;
  }
}



.cardExpandButton2{
  padding: 10px;
  border-radius: 25px;
  background: #232220;
  width: 120px;
  height: 85px;
  overflow: visible;
  position: relative;
  z-index: 99;
  top: 48vh;
  left: 21vw;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.open{
    left: 80vw;
  }
}